<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper">
        <el-form
          :inline="true"
          status-icon
          label-position="right"
          label-width="96px"
          :model="formInline"
          ref="formInline"
          class="demo-form-inline"
        >
          <el-form-item :label="$t('searchModule.park_name')">
            <my-component
              ref="parkInput"
              :areaIds="formInline.streetId ? formInline.streetId : formInline.areaId"
              @valueChange="completeValue"
              slaveRelations="0,1"
            >
            </my-component>
          </el-form-item>
          <el-form-item :label="$t('searchModule.date')" prop="showDate" label-width="70px">
            <el-select v-model.trim="formInline.showDate" filterable size="15" style="width: 100px">
              <el-option label="日" value="0">日</el-option>
              <el-option label="月" value="1">月</el-option>
              <!-- <el-option label="年" value="year">年</el-option>-->
              <!--						<el-option label="自定义" value="custom">自定义</el-option>-->
            </el-select>
          </el-form-item>
          <el-form-item>
            <timeRangePick
              @timeChange="timeChange"
              ref="timeRangePicker"
              :type="formInline.showDate == '0' ? 'daterange' : 'monthrange'"
              :valueFormat="formInline.showDate == '0' ? 'yyyy-MM-dd' : 'yyyy-MM'"
            />
            <!-- <el-date-picker v-model="startTime"
                            :picker-options="pickerOptions"
                            type="date"
                            placeholder="选择日期"
                            v-show="formInline.showDate == '0'"></el-date-picker>
            <span v-show="formInline.showDate == '0'">至</span>
            <el-date-picker v-model="endTime"
                            :picker-options="pickerOptions"
                            type="date"
                            placeholder="选择日期"
                            v-show="formInline.showDate == '0'"></el-date-picker>
            <el-date-picker v-model="startTime"
                            :picker-options="pickerOptions"
                            type="month"
                            placeholder="选择月"
                            v-show="formInline.showDate == '1'"></el-date-picker>
            <span v-show="formInline.showDate == '1'">至</span>
            <el-date-picker v-model="endTime"
                            :picker-options="pickerOptions"
                            type="month"
                            placeholder="选择月"
                          v-show="formInline.showDate == '1'"></el-date-picker> -->
          </el-form-item>
          <el-form-item class="but">
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="
                pageNum = 1;
                searchData();
              "
              :loading="loading"
              >查询
            </el-button>
            <el-button
              type="primary"
              icon="el-icon-upload2"
              @click="
                pageNum = 1;
                exportExcelReport();
              "
              :loading="loading"
              v-if="$route.meta.authority.button.export"
              >导出
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper paddingB20">
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <el-table-column align="center" prop="parkName" :label="$t('searchModule.park_name')" width="150">
          </el-table-column>
          <el-table-column align="center" prop="reportDate" :label="$t('searchModule.date')" width="150">
          </el-table-column>
          <el-table-column
            :formatter="moneyFormatter"
            align="center"
            prop="totalShouldPay"
            label="应收"
          >
            <template slot="header">
              <div>
                实收
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="支付时间为当日的已支付订单实付总额 + 优惠总额"
                  placement="top"
                >
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          <el-table-column :formatter="moneyFormatter" align="center" prop="totalAgioPay">
            <template slot="header">
              <div>
                优惠
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="支付时间为当日的已支付订单优惠总额"
                  placement="top"
                >
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="用户支付">
            <el-table-column
              :formatter="moneyFormatter"
              align="center"
              prop="totalActualPay"
              width="150"
            >
              <template slot="header">
                <div>
                  实付总额
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="支付时间为当日的已支付订单实付总额"
                    placement="top"
                  >
                    <i class="el-icon-question"></i>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
            <!-- <el-table-column
              :formatter="moneyFormatter"
              align="center"
              prop="totalShouldPay"
              label="应收"
            >
              <template slot="header">
                <div>
                  实收
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="支付时间为当日的已支付订单实付总 + 优惠总额"
                    placement="top"
                  >
                    <i class="el-icon-question"></i>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
            <el-table-column :formatter="moneyFormatter" align="center" prop="totalAgioPay">
              <template slot="header">
                <div>
                  优惠
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="支付时间为当日的已支付订单优惠总额"
                    placement="top"
                  >
                    <i class="el-icon-question"></i>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column> -->

            <el-table-column
              :key="index"
              v-for="(item, index) in channelTypeList"
              :formatter="moneyFormatter"
              align="center"
              :prop="item.code + ''"
              :label="item.desc"
            >
            </el-table-column>
            <!-- <el-table-column
              :formatter="moneyFormatter"
              align="center"
              prop="onlineWechat"
              label="微信实收"
            >
            </el-table-column>
            <el-table-column
              width="80"
              :formatter="moneyFormatter"
              align="center"
              prop="onlineAlipay"
              label="支付宝实收"
            >
            </el-table-column>
            <el-table-column
              width="120"
              :formatter="moneyFormatter"
              align="center"
              prop="cmb"
              label="招商银行实收"
            >
            </el-table-column>
            <el-table-column
              :formatter="moneyFormatter"
              align="center"
              prop="offlineCash"
              label="现金支付"
            >
            </el-table-column> -->
            <!-- <el-table-column
              width="110"
              :formatter="moneyFormatter"
              align="center"
              prop="subTotalActualPay"
            >
              <template slot="header">
                <div>
                  实收小计
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="微信实收+支付宝实收+余额实收+现金支付"
                    placement="top"
                  >
                    <i class="el-icon-question"></i>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column> -->
          </el-table-column>
          <!-- <el-table-column
            :formatter="moneyFormatter"
            align="center"
            prop="otherParkpay"
            width="150"
          >
            <template slot="header">
              <div>
                车场自收
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="通过非爱泊车平台渠道支付的停车费，如第三方接入的车场"
                  placement="top"
                >
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </div>
            </template>
          </el-table-column> -->
          <!-- <el-table-column
            :formatter="moneyFormatter"
            align="center"
            prop="totalActualPay"
            width="150"
          >
            <template slot="header">
              <div>
                实付总额
                <el-tooltip class="item" effect="dark" content="支付时间为当日的已支付订单实付总额" placement="top">
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </div>
            </template>
          </el-table-column> -->
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper bgFFF">
          <el-pagination
            v-if="total != 0"
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import timeRangePick from "@/components/timePicker";
import { dateFormat, exportExcelNew } from "@/common/js/public.js";
import AreaChart from "@/components/areaChart/areaChart";
import StatisticalSearch from "@/components/statisticalSearch/statisticalSearch";
import myComponent from "@/components/autocomplete/myautoComponent";

export default {
  name: "parkIncomeForm",
  data() {
    // const startTime = new Date();
    // startTime.setTime(startTime.getTime() - 31 * 24 * 60 * 60 * 1000);
    // const endTime = new Date();
    // endTime.setTime(endTime.getTime() - 3600 * 1000 * 24);
    return {
      // pickerOptions: {
      //   disabledDate (time) {
      //     return time.getTime() > endTime;
      //   }
      // },
      pageNum: 1,
      pageSize: 15,
      total: 0,
      // startTime: startTime,
      // endTime: endTime,
      parkList: [],
      tableData: [],
      channelTypeList: [],
      searchType: "historyData",
      loading: false,
      type1: "c3",
      type2: "c4",
      formInline: {
        parkId: "",
        parkName: "",
        showDate: "0",
        startTime: "",
        endTime: "",
      },
    };
  },
  methods: {
    timeChange(timeArr) {
      this.formInline.startTime = timeArr[0];
      this.formInline.endTime = timeArr[1];
    },
    moneyFormatter(row, column, cellValue, index) {
      if (cellValue) {
        return Number(cellValue / 100).toFixed(2);
      } else {
        return "0.00";
      }
    },
    getTimeNow() {
      const startTime = new Date();
      const endTime = new Date();
      if (this.formInline.showDate == "0") {
        startTime.setTime(startTime.getTime() - 31 * 24 * 60 * 60 * 1000);
        endTime.setTime(endTime.getTime() - 3600 * 1000 * 24);
      } else {
        startTime.setTime(startTime.getTime() - 365 * 24 * 3600 * 1000);
        endTime.setTime(endTime.getTime() - 31 * 24 * 3600 * 1000);
      }
      this.startTime = startTime;
      this.endTime = endTime;
      // console.log('开始时间' + this.startTime);
      // console.log('结束时间' + this.startTime);
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    completeValue(item) {
      if (item) {
        this.formInline.parkId = item.parkId;
        this.formInline.parkName = item.parkName;
      } else {
        this.formInline.parkId = "";
        this.formInline.parkName = "";
      }
    },
    /* 停车场 */
    parkData() {
      this.$axios
        .get("/acb/2.0/systems/loginUser/getParkName", {
          data: {
            page: 0,
            pageSize: 0,
          },
        })
        .then((res) => {
          this.parkList = res.value.list;
        });
    },
    // 时间判断
    showLog() {
      if (this.formInline.startTime && this.formInline.endTime) {
        let time = new Date(this.formInline.endTime) - new Date(this.formInline.startTime);
        time = time / (24 * 60 * 60 * 1000);
        let diff = "";
        if (this.formInline.showDate === "0") {
          if (time >= 31) {
            this.$alert("查询时间不能大于31天");
            return false;
          } else {
            return true;
          }
        } else {
          if (time > 365) {
            this.$alert("查询时间不能大于12个月");
            return false;
          } else {
            return true;
          }
        }
      } else {
        this.$alert("日期不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
    },
    searchData() {
      if (this.formInline.parkId != "") {
        this.$refs.parkInput.setShowVal(this.formInline.parkName);
        this.getReport();
      } else {
        this.$refs.parkInput.setShowVal("");
        this.getReport();
      }
    },
    // 停车场收入报表 查询
    getReport() {
      let flag = this.showLog();
      if (flag) {
        this.$axios
          .get("/acb/2.0/financeStatistic/parkIncome", {
            data: {
              page: this.pageNum,
              pageSize: this.pageSize,
              parkId: this.formInline.parkId,
              dateType: this.formInline.showDate, // 必填，day/month
              startDate: this.formInline.startTime, // 必填，格式要求day:yyyy-MM-dd,month:yyyy-MM
              endDate: this.formInline.endTime, // 必填，格式要求day:yyyy-MM-dd,month:yyyy-MM
            },
          })
          .then((res) => {
            if (res.state == 0 && res.desc === "success") {
              this.tableData = res.value.list;
              this.total = res.value.total * 1 || 0;
            } else {
              this.tableData = [];
              this.total = 0;
            }
          });
      }
    },
    getChannelTypeList() {
      this.$axios.get("/acb/2.0/channelType/list").then((res) => {
        console.log(res, "res");
        this.channelTypeList = res.value;
      });
    },
    // 停车场收入报表 导出
    exportExcelReport() {
      let flag = this.showLog();
      if (flag) {
        exportExcelNew("/acb/2.0/financeStatistic/parkIncome/export", {
          dateType: this.formInline.showDate,
          startDate: this.formInline.startTime,
          endDate: this.formInline.endTime,
          parkId: this.formInline.parkId,
        });
      }
    },
  },
  components: {
    myComponent,
    timeRangePick,
  },
  beforeDestroy() {},
  created() {
    this.getChannelTypeList();
    this.parkData();
  },
  computed: {},
  mounted() {
    this.searchData();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.pagerWrapper
  margin-top 0
  .el-pagination
    padding-top 20px
    text-align right
.breadcrumb
  height 35px
.content
  .searchWrapper
    overflow hidden
    padding 22px 12px 0 22px
    margin-bottom 20px
  .list
    margin-top 20px
    .el-col
      padding-bottom 18px
      height 320px
      .grid-content
        padding-top 15px
        height 100%
      h2
        font-size 18px
        text-align center
  .but
    float: right
</style>
